import App from './App.vue'
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import VueVimeoPlayer from 'vue-vimeo-player'

import axios from 'axios'
import i18n from './i18n'
import moment from 'moment-business-days'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.filter('dateFormat', function (value) {
    if (!value) {
        return ''
    } else {
        return moment(value).format('YYYY-MM-DD')
    }
})

Vue.filter('dateTimeFormat', function (value) {
    if (!value) {
        return ''
    } else {
        return moment(value).format('YYYY-MM-DD hh:mm a')
    }
})

Vue.filter('number', function (value) {
    if (value == null) {
        return ''
    } else {
        return value.toLocaleString()
    }
})

Vue.filter('number0', function (value) {
    if (value == null) {
        return ''
    } else {
        return parseFloat(value).toFixed(0)
    }
})

Vue.filter('number2', function (value) {
    if (value == null) {
        return ''
    } else {
        return parseFloat(value).toFixed(2)
    }
})

Vue.filter('number2compact', function (value) {
    if (value == null) {
        return ''
    } else {
        return (parseFloat(value) / 1000000).toLocaleString(undefined, {'minimumFractionDigits': 2, 'maximumFractionDigits': 2}) + 'M'
    }
})

Vue.filter('number2compact2', function (value) {
    if (value == null) {
        return ''
    } else {
        return (parseFloat(value) / 1000000).toLocaleString(undefined, {'minimumFractionDigits': 1, 'maximumFractionDigits': 1})
    }
})

Vue.filter('percentage', function (value) {
    if (value == null) {
        return ''
    } else {
        return (value > 0 ? '+' : '') + (Math.round(value * 1000) / 10).toFixed(1) + '%'
    }
})

Vue.filter('performance', function (value) {
    if (value == null) {
        return ''
    } else {
        return value > 0 ? '+' + value.toFixed(1) : value.toFixed(1)
    }
})

Vue.filter('pending', function (value) {
    if (value == null || value === '') {
        return 'evaluation pending'
    } else {
        return value
    }
})

Vue.filter('anticipated', function (value) {
    if (value == null || value === '') {
        return moment().businessAdd(1).format('YYYY-MM-DD') + ' (anticipated)'
    } else {
        return value
    }
})

new Vue({
    vuetify: vuetify,
    router: router,
    render: h => h(App),
    i18n: i18n,
    store: store
}).$mount('#app')

Vue.use(HighchartsVue)
Vue.use(VueVimeoPlayer)

axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        // if (error.response.status === 400) {
        //     store.dispatch('snackbar', 'frontend error')
        // }

        if (error.response.status === 401) {
            store.dispatch('logout')

            router.push('/login')
        }

        // if (error.response.status === 500) {
        //     store.dispatch('snackbar', 'backend error')
        // }

        return Promise.reject(error)
    }
)
